import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, lastValueFrom, Observable, of, zip } from 'rxjs';
import { catchError, first, map, mergeMap, retry, switchMap } from 'rxjs/operators';
import { Delivery } from '../../model/group/group';
import { DeliveryFixVersion } from '../../model/jira/delivery-fix-version.model';
import { JiraAbapBody, JiraBulkCreateIssueBody, JiraBulkCreateIssueResponse } from '../../model/jira/jira-bulk';
import { JiraIssueFields, JiraVersion } from '../../model/jira/jira-issue-fields';
import { JiraList } from '../../model/jira/jira-list';
import { XrayTestStatus } from '../../model/jira/jira-xray';
import { JiraIssue, JiraRelease, Requirement } from '../../model/jira/release-item';
import { RequirementDetail } from '../../model/requirement-detail/requirement-detail';
import { RequirementTestcaseResult } from '../../model/requirement-detail/requirement-detail-testcase';
import { ReleaseDecisionEntityTypes } from '../../ngrx/model/group-type-microdelivery';
import { ReleaseDecisionInformation, ReleaseIssueType } from '../access-api/access-api.service';
import { AlertService, AlertType } from '../alert/alert.service';
import { EnvironmentService } from '../environment.service';

class JqlRequestBody {
  jql: string;
  fields?: string[];
  expand?: string[];
  startAt?: number = 0;
  maxResults?: number;
}

@Injectable({
  providedIn: 'root',
})
export class JiraService {
  constructor(
    private readonly http: HttpClient,
    private readonly environmentService: EnvironmentService,
    private readonly alertService: AlertService,
  ) {}

  _baseUrl: string;

  get baseUrl() {
    if (!this._baseUrl) {
      this._baseUrl = this.environmentService.getBaseUrl();
    }
    return this._baseUrl;
  }

  getRequirementsForDelivery(deliveryFixVersions: DeliveryFixVersion[]): Observable<JiraList> {
    if (Array.isArray(deliveryFixVersions) && deliveryFixVersions.length) {
      const projectNames = deliveryFixVersions.map((v) => `'${v.projectKey}'`);
      const fixVersions = deliveryFixVersions.map((v) => `'${v.versionName}'`);
      if (!projectNames.length || !fixVersions.length) {
        return of({ issues: [], total: 0 });
      }
      return this.postJql(
        `fixVersion in (${fixVersions.join(',')}) and 'Traceability Relevant' = Yes
            and project in (${projectNames.join(',')})`,
      );
    }
    return of({ issues: [], total: 0 });
  }

  getRequirementsByComponents(componentNames: string[], deliveryFixVersions: DeliveryFixVersion[]) {
    if (componentNames?.length && deliveryFixVersions?.length) {
      const projectNames = deliveryFixVersions.map((v) => `'${v.projectKey}'`);
      const fixVersions = deliveryFixVersions.map((v) => `'${v.versionName}'`);
      const componentNamesJoined = componentNames
        .filter(Boolean)
        .map((name) => `'${name}'`)
        .join(',');
      return this.postJql(
        `'Traceability Relevant' = Yes
              and component in (${componentNamesJoined})
              and fixVersion not in (${fixVersions.join(',')})
              and fixVersion in releasedVersions(${projectNames.join(',')})`,
      );
    }
    return of({ issues: [], total: 0 });
  }

  getDeliveryFixVersions(deliveryGuid: string): Observable<DeliveryFixVersion[]> {
    return this.http.get<DeliveryFixVersion[]>(`https://${this.baseUrl}/zprs/api/jira/deliveryfixversions?deliveryGuid=${deliveryGuid}`);
  }

  getRequirementsForParentIssue(issue: string): Observable<JiraList> {
    const jql = `(issue in linkedIssues("${issue}", "parent-of") OR "Epic Link" = "${issue}") AND "Traceability Relevant" = Yes`;
    return this.postJql(jql).pipe(retry(1));
  }

  async getManualTestTasksForRequirements(
    requirements: Requirement[],
    release: Delivery,
    deliveryFixVersions: DeliveryFixVersion[],
  ): Promise<RequirementTestcaseResult[]> {
    if (!requirements || !requirements.length) {
      return [];
    }

    const requirementKeys = requirements.map((requirement) => requirement.key);

    const isDeliveryJiraRelease = release.isMicrodelivery && release.releaseDecisionEntityType === ReleaseDecisionEntityTypes.JIRA_RELEASE;
    const versionNames = isDeliveryJiraRelease ? [release.name] : deliveryFixVersions.map((fixVersion) => fixVersion.versionName);

    if (!versionNames.length) {
      return [];
    }
    // This is only a temp workaround. The IF backend must use the POST /search Jira api instead of GET
    // so we must not split the requirements
    const testcaseResults: RequirementTestcaseResult[] = [];
    const chunk = 100;

    while (requirementKeys.length > 0) {
      const jql =
        `parent in (${requirementKeys.splice(0, chunk).join(',')}) AND type = 'Test Task'` +
        `AND  (labels not in ('CumulusAutomatedTest') OR labels is EMPTY)`;

      const jqlRequest$ = this.postJql(jql, ['parent', 'customfield_20141']).pipe(
        map((jiraList) => {
          jiraList.issues.forEach((issue) => {
            testcaseResults.push(this.requirementTestCaseResultFromTestTask(issue, versionNames));
          });
        }),
        catchError(() => {
          this.alertService.createAlert('Error loading Manual Test Tasks from Jira', AlertType.ERROR);
          return of([]);
        }),
      );
      await lastValueFrom(jqlRequest$);
    }
    return testcaseResults;
  }

  async getXrayTestTasksForRequirements(
    requirements: Requirement[],
    release: Delivery,
    deliveryFixVersions: DeliveryFixVersion[],
  ): Promise<RequirementTestcaseResult[]> {
    if (!requirements || !requirements.length) {
      return [];
    }

    const isDeliveryJiraRelease = release.isMicrodelivery && release.releaseDecisionEntityType === ReleaseDecisionEntityTypes.JIRA_RELEASE;
    const versionNames = isDeliveryJiraRelease
      ? [release.name]
      : deliveryFixVersions.map((fixVersion) => fixVersion.versionName).filter((value, index, self) => self.indexOf(value) === index);

    if (!versionNames.length) {
      return [];
    }

    const requirementKeys = requirements.map((requirement) => requirement.key);

    const additionalFields: string[] = ['issuelinks', this.environmentService.getXRayStatusFieldKey()];
    const projectKeys = deliveryFixVersions.map((fixVersion) => fixVersion.projectKey);
    const versionNameString = versionNames.map((versionName) => `'${versionName}'`).join(',');
    const jqlForTestInProjects = projectKeys.map((projectKey) => {
      return `issue in testsWithReqVersion('${projectKey}', ${versionNameString})`;
    });

    const jql = `issuetype = 'Test' and (${jqlForTestInProjects.join(' or ')})`;

    const jiraList = await lastValueFrom(this.postJql(jql, additionalFields));
    const requirementsTestcases: RequirementTestcaseResult[] = [];

    // list of tests with outward issues and results
    jiraList.issues.forEach((xrayTest) => {
      // check each test if it has any of the requirement key in outwward issue and remove all others
      const outwardIssuesOfTest = xrayTest.fields.issuelinks.filter((issuelink) =>
        issuelink.outwardIssue ? requirementKeys.includes(issuelink.outwardIssue.key) : false,
      );
      // do the mapping for the rest of outward issues to RequirementTestcaseResult
      outwardIssuesOfTest.forEach((outwardIssue) =>
        requirementsTestcases.push(this.mapXrayTest(outwardIssue, xrayTest, versionNames, isDeliveryJiraRelease)),
      );
    });
    return requirementsTestcases;
  }

  postJql(jql: string, additionalFields: string[] = [], expandFields: string[] = []): Observable<JiraList> {
    const requestUrl = `https://${this.baseUrl}/zprs/api/jira/jqlQuery`;
    const jqlRequestBody: JqlRequestBody = {
      jql: jql.replace(/[\n\t\r\v\f]+/g, ' '), // remove double whitespaces, tabs and newlines
      fields: additionalFields,
      expand: expandFields,
    };
    return this.doJiraPOSTRequest(requestUrl, jqlRequestBody);
  }

  doJiraPOSTRequest(requestUrl: string, jqlRequestBody: JqlRequestBody): Observable<JiraList> {
    return this.http.post<JiraList>(requestUrl, jqlRequestBody).pipe(
      retry(1),
      mergeMap((result) => {
        if (result.total > result.issues.length) {
          const followUpRequests: Observable<JiraList>[] = [];
          let issuesToFetch = result.total - result.maxResults;
          while (issuesToFetch > 0) {
            jqlRequestBody.startAt = (followUpRequests.length + 1) * 1000;
            followUpRequests.push(this.http.post<JiraList>(requestUrl, jqlRequestBody).pipe(first(), retry(1)));
            issuesToFetch -= result.maxResults;
          }
          return zip(of(result), combineLatest(followUpRequests));
        }
        return zip(of(result), of([]));
      }),
      map((allResults) => {
        const resultJiraList = allResults[0];
        const followUpRequestResults = allResults[1];

        followUpRequestResults.forEach((requestResult) => {
          resultJiraList.issues = resultJiraList.issues.concat(requestResult.issues);
        });
        resultJiraList.maxResults = resultJiraList.issues.length;

        return resultJiraList;
      }),
    );
  }

  doJiraListRequest(requestUrl): Observable<JiraList> {
    return this.http.get<JiraList>(requestUrl).pipe(
      retry(1),
      mergeMap((result) => {
        if (result.total > result.issues.length) {
          const followUpRequests: Observable<JiraList>[] = [];
          let issuesToFetch = result.total - result.maxResults;

          while (issuesToFetch > 0) {
            const jql = requestUrl + `&startAt=${(followUpRequests.length + 1) * 1000}`;
            followUpRequests.push(this.http.get<JiraList>(jql).pipe(first(), retry(1)));
            issuesToFetch -= result.maxResults;
          }

          return zip(of(result), combineLatest(followUpRequests));
        }
        return zip(of(result), of([]));
      }),
      map((allResults) => {
        const resultJiraList = allResults[0];
        const followUpRequestResults = allResults[1];

        followUpRequestResults.forEach((requestResult) => {
          resultJiraList.issues = resultJiraList.issues.concat(requestResult.issues);
        });
        resultJiraList.maxResults = resultJiraList.issues.length;

        return resultJiraList;
      }),
    );
  }

  getRequirementsForRelease(releases: string[]): Observable<JiraList> {
    return this.doJiraListRequest(`https://${this.baseUrl}/zprs/api/jira/requirementsForRelease?releases=${releases.join(',')}`);
  }

  getJiraLinkForRelease(projectKey: string, id: string | number): string {
    return `${this.environmentService.getJiraUrl()}/projects/${projectKey}/versions/${id}`;
  }

  getJiraLinkForIssue(issueKey: string): string {
    return `${this.environmentService.getJiraUrl()}/browse/${issueKey}`;
  }

  getDefaultReleasesForMicroDeliveries(delivery: Delivery, deliveryFixVersions: DeliveryFixVersion[]): Observable<JiraIssue[]> {
    const projectNames = deliveryFixVersions.map((v) => `'${v.projectKey}'`);
    const fixVersions = deliveryFixVersions.map((v) => `'${v.versionName}'`);
    if (!projectNames.length || !fixVersions.length) {
      return of([]);
    }
    return this.postJql(
      `fixVersion in (${fixVersions.join(',')}) and project in (${projectNames.join(',')})
        and issueType = ${this.environmentService.getIssueTypeIdMicroDelivery()}`,
      ['created', 'components', 'resolution', 'resolutiondate'],
      ['changelog'],
    ).pipe(map(this.mapChangelog));
  }

  getReleasesForJiraFilter(releaseDecisionInformation: ReleaseDecisionInformation): Observable<JiraIssue[]> {
    return this.http
      .get<any>(`https://${this.baseUrl}/zprs/api/jira/getFilter?filterId=${releaseDecisionInformation.releaseIssueFilterId}`)
      .pipe(
        retry(1),
        switchMap((result) => {
          return this.postJql(result.jql, ['created', 'components', 'resolution', 'resolutiondate'], ['changelog']).pipe(
            map(this.mapChangelog),
          );
        }),
      );
  }

  getReleasesForEpicProject(releaseDecisionInformation: ReleaseDecisionInformation): Observable<JiraIssue[]> {
    const issueType = this.mapIssueTypeToJiraIssueType(releaseDecisionInformation.releaseIssueType);
    const jql = `project = ${releaseDecisionInformation.releaseIssueProjectId} AND issueType = "${issueType}"`;
    return this.postJql(jql, ['created', 'components', 'resolution', 'resolutiondate'], ['changelog']).pipe(
      retry(1),
      map(this.mapChangelog),
    );
  }

  mapChangelog = (results): JiraIssue[] => {
    return results.issues.map((issue) => {
      issue.changelog = issue.changelog.histories;
      return issue;
    });
  };

  getReleasesForProjects(releaseDecisionInformation: ReleaseDecisionInformation): Observable<JiraRelease[]> {
    const projects = releaseDecisionInformation.backlogIds.join(',');
    return this.http.get<JiraRelease[]>(`https://${this.baseUrl}/zprs/api/jira/getProjectFixVersions?projects=${projects}`).pipe(
      retry(1),
      map((releases) => {
        if (!releaseDecisionInformation.releaseFilterPrefix) {
          return releases;
        }
        return releases.filter((release) => release.name.startsWith(releaseDecisionInformation.releaseFilterPrefix));
      }),
    );
  }

  getJiraLink(requirement: Requirement | JiraIssue): string {
    const hostLink = requirement.self.match(/^https:\/\/?([^:/\s]+)/gm);
    return `${hostLink[0]}/browse/${requirement.key}`;
  }

  updateStatus(updateBody: JiraAbapBody) {
    return this.http.post(`https://${this.baseUrl}/zprs/api/jira/updateissuestatus`, updateBody);
  }

  exportTestcases(requirementDetail: RequirementDetail[]): Observable<any> {
    const uniqueProjectKeys = [...new Set(requirementDetail.map((item) => item.projectKey))];
    const requirementIssueKeys = requirementDetail.map((requirement) => requirement.key);

    // We need to send two separate create requests to transition the tasks with status 'OK'.
    // If we use JQLQuery right after create, we run into timing issues
    const [bulkTestedBody, bulkNoTransitionBody] = this.jiraTestTasks(requirementDetail);

    // delete automated lightweight test tasks if there are
    return this.filterByAutomatedTestLabel(uniqueProjectKeys, requirementIssueKeys).pipe(
      mergeMap((results) => (results.issues.length ? this.deleteJiraTasks(results) : of([]))),
      // create new jira test tasks
      mergeMap((_) => (bulkNoTransitionBody.issueUpdates.length ? this.createJiraTestTasks(bulkNoTransitionBody) : of(null))),
      mergeMap((_) => (bulkTestedBody.issueUpdates.length ? this.createJiraTestTasks(bulkTestedBody) : of(null))),
      // transition new jira test tasks with status 'OK'
      mergeMap((jiraBulkCreateIssueResponse: JiraBulkCreateIssueResponse) => {
        if (!jiraBulkCreateIssueResponse) {
          return of({ issues: [] });
        }
        const jiraAbapBody = { issues: jiraBulkCreateIssueResponse.issues.map((issue) => ({ key: issue.key })) };
        return this.updateStatus(jiraAbapBody);
      }),
    );
  }

  /*
  Aggregates the final xray result status of a test
  One RED -> all red
  One Yellow -> all yellow
  else green
   */
  private aggregateXrayStatus(statusArray) {
    const testStatus = {
      status: true,
      untested: false,
    };
    if (statusArray.length === 0) {
      testStatus.untested = true;
      testStatus.status = false;
      return testStatus;
    }
    for (const status of statusArray) {
      switch (status.statusResults[0].latestFinal) {
        case XrayTestStatus.FAIL:
          testStatus.untested = false;
          testStatus.status = false;
          return testStatus;
        case XrayTestStatus.TODO:
        case XrayTestStatus.ABORTED:
        case XrayTestStatus.EXECUTING:
        case XrayTestStatus.BLOCKED:
          testStatus.untested = true;
          testStatus.status = false;
          break;
      }
    }
    return testStatus;
  }

  private mapXrayTest(outwardIssue, xrayTest, versionNames: string[], isFixVersion: boolean): RequirementTestcaseResult {
    const xRayStatusFieldKey = this.environmentService.getXRayStatusFieldKey();
    const xrayTestStatuses = xrayTest.fields[xRayStatusFieldKey].statuses;
    let xrayStatusFinal;
    const xrayStatusForVersion = xrayTestStatuses.find((status) => versionNames.includes(status.group));

    if (!xrayStatusForVersion) {
      xrayStatusFinal = isFixVersion ? { untested: true, status: false } : this.aggregateXrayStatus(xrayTestStatuses);
    } else {
      xrayStatusFinal = this.aggregateXrayStatus([xrayStatusForVersion]);
    }

    return {
      name: [xrayTest.key],
      pipelineKey: '',
      pipelineRunId: 0,
      requirementKey: outwardIssue.outwardIssue.key,
      skipped: false,
      untested: xrayStatusFinal.untested,
      hasIncorrectVersion: false,
      source: '',
      sourceReference: '',
      status: xrayStatusFinal.status,
      stepResultTypeName: 'xray',
      jiraLink: this.getJiraLink(xrayTest),
    };
  }

  private requirementTestCaseResultFromTestTask(testTask: JiraIssue, versionNames: string[]): RequirementTestcaseResult {
    const fixVersions: JiraVersion[] = testTask.fields.fixVersions;
    const isInFixVersions = fixVersions.some((fixVersion) => versionNames.includes(fixVersion.name));
    return {
      name: [testTask.fields.summary ? `${testTask.key}: ${testTask.fields.summary}` : testTask.key],
      pipelineKey: '',
      pipelineRunId: 0,
      requirementKey: testTask.fields.parent.key,
      skipped: false,
      untested: !testTask.fields.customfield_20141 || testTask.fields.customfield_20141.value === 'Untested',
      hasIncorrectVersion: testTask.fields.customfield_20141 ? !isInFixVersions : false,
      source: '',
      sourceReference: '',
      status: testTask.fields.customfield_20141 && testTask.fields.customfield_20141.value === 'OK',
      stepResultTypeName: 'manual',
      jiraLink: this.getJiraLink(testTask),
    };
  }

  private mapIssueTypeToJiraIssueType(issueType: ReleaseIssueType): string {
    let issueTypeString: string;
    switch (issueType) {
      case ReleaseIssueType.Epic:
        issueTypeString = 'Epic';
        break;
      case ReleaseIssueType.UserStory:
        issueTypeString = 'User Story';
        break;
    }
    return issueTypeString;
  }

  private getJiraTestStatusFromTestcase(testcase?: RequirementTestcaseResult): string {
    if (testcase && testcase.skipped) {
      return `Untested`;
    } else if (testcase && testcase.status) {
      return `OK`;
    } else if (testcase) {
      return `Not OK`;
    }
    return `Untested`;
  }

  private jiraIssueFromRequirement(requirement: RequirementDetail, testcase?: RequirementTestcaseResult): JiraIssueFields {
    return {
      summary: `Automated Test for ` + requirement.key,
      description: testcase
        ? testcase.name.map((name) => `${name}\nSource File: ${testcase.source}`).join(`\n\n`)
        : 'No tests found for ' + requirement.key,
      labels: [`CumulusAutomatedTest`],
      customfield_20141: { value: this.getJiraTestStatusFromTestcase(testcase) },
      issuetype: { name: `Test Task` },
      project: { key: requirement.projectKey },
      parent: { key: requirement.key },
      fixVersions: requirement.fixVersions.map((version) => ({ name: version.name })),
    };
  }

  private createJiraTestTasks(bulkBody: JiraBulkCreateIssueBody): Observable<JiraBulkCreateIssueResponse> {
    return this.http.post<JiraBulkCreateIssueResponse>(`https://${this.baseUrl}/zprs/api/jira/issuebulk`, bulkBody, {
      headers: new HttpHeaders().set(`Content-Type`, `application/JSON`),
    });
  }

  private jiraTestTasks(requirementDetail: RequirementDetail[]): [JiraBulkCreateIssueBody, JiraBulkCreateIssueBody] {
    const bulkTestedBody: JiraBulkCreateIssueBody = { issueUpdates: [] };
    const bulkNoTransitionBody: JiraBulkCreateIssueBody = { issueUpdates: [] };
    for (const requirement of requirementDetail) {
      if (!requirement.testcases.length) {
        // If there are no transitioned, there is no case in which we can transition this to Tested.
        bulkNoTransitionBody.issueUpdates.push({ fields: this.jiraIssueFromRequirement(requirement) });
        continue;
      }
      for (const testcase of requirement.testcases) {
        if (testcase.stepResultTypeName === 'manual') {
          continue;
        }
        if (this.getJiraTestStatusFromTestcase(testcase) === 'OK') {
          bulkTestedBody.issueUpdates.push({ fields: this.jiraIssueFromRequirement(requirement, testcase) });
          continue;
        }
        bulkNoTransitionBody.issueUpdates.push({ fields: this.jiraIssueFromRequirement(requirement, testcase) });
      }
    }
    return [bulkTestedBody, bulkNoTransitionBody];
  }

  private filterByAutomatedTestLabel(projectKeys: string[], requirementIssueKeys: string[]): Observable<JiraAbapBody> {
    const jql = `project in (${projectKeys.toString()}) AND labels in (CumulusAutomatedTest) AND parent in (${requirementIssueKeys.toString()})`;
    return this.postJql(jql).pipe(
      retry(1),
      map((results) => results.issues.map((x) => ({ key: x.key }))),
      map((results) => ({ issues: results })),
    );
  }

  private deleteJiraTasks(deleteBody: JiraAbapBody) {
    return this.http.post(`https://${this.baseUrl}/zprs/api/jira/issuebulkdelete`, deleteBody);
  }
}
